<template>
  <div>
    <v-app-bar
      color="#283c86"
      dark
    >
      <v-app-bar-nav-icon v-if="isLoggedIn" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>GTO Supplier Portal</v-toolbar-title>

      <v-spacer></v-spacer>
      <div v-if="isLoggedIn" style="display: flex; align-items: center;">
        <p class="companyName">{{ this.$store.state.supplierName }}</p>
        <v-btn color="blue-grey darken-1" @click="logOut">
          Log Out
        </v-btn>
      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
        >

          <v-list-item @click="routeTo('/account')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>

          <v-list-item @click="routeTo('/contacts')">
            <v-list-item-icon>
              <v-icon>mdi-contacts</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item>

          <v-list-item @click="routeTo('/products')">
            <v-list-item-icon>
              <v-icon>mdi-warehouse</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Our Products</v-list-item-title>
          </v-list-item>

          <v-list-item @click="routeTo('/add-product')">
            <v-list-item-icon>
              <v-icon>mdi-plus-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Add New Product</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    logOut() {
      this.$store.commit('logOut');
      this.$router.push({ path: '/' });
    },
    routeTo(dest) {
      this.$router.push({ path: dest });
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display:flex;
  justify-content:space-between;
  align-items: center;
  width:100vw;
  background-color: #CFD8DC;
  padding: 10px;
}
.header > div {
  max-width: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.companyName {
  margin: 3px 15px 0 0;
}
@media only screen and (max-width: 1200px) {
.companyName {
    display: none;
  }
}
</style>
