<template>
<v-card
  elevation="2"
  class="gtoCard"
>
  <v-form>
    <v-container>
      <v-row>
        <v-col>

          <v-text-field
            v-model="userData.username"
            label="Username"
            required
          ></v-text-field>

          <br>

          <v-text-field
            v-model="userData.firstName"
            label="First Name"
            required
          ></v-text-field>

          <br>

          <v-text-field
            v-model="userData.lastName"
            label="Last Name"
            required
          ></v-text-field>

          <br>

          <v-card-actions>
            <v-btn color="#283c86" text @click="change">Log In</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="userData.username.length < 1 || userData.firstName.length < 1 || userData.lastName.length < 1"
              style="background: #40832F; color: #FFF;"
              @click="resetPassword"
            >
              Reset Password
            </v-btn>
          </v-card-actions>

        </v-col>
      </v-row>
    </v-container>
  </v-form>
</v-card>
</template>

<script>
export default {
  name: 'ResetPasswordCard',
  data() {
    return {
      userData: {
        username: '',
        firstName: '',
        lastName: '',
      },
    };
  },
  methods: {
    change() {
      this.$emit('child-data');
    },
    async resetPassword() {
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/reset-password`;
        const headers = { 'Content-Type': 'application/json' };
        const resetPasswordCall = () => fetch(url, { method: 'PATCH', headers, body: JSON.stringify(this.userData) });
        const response = await resetPasswordCall();
        console.log(response);
        if (response.status !== 200) return this.$store.commit('notify', 'Error. Make sure username is correct.');
        return this.$store.commit('notify', 'Password has been reset. GTO will reach out to you with your new password.');
      } catch (err) {
        console.log(err);
        return this.$store.commit('notify', 'Error. Make sure username is correct.');
      }
    },
  },
};
</script>
