<template>
  <v-app>
    <Header />
    <v-main>
      <router-view />
    </v-main>
    <v-snackbar :value="show">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="orange lighten-2" text v-bind="attrs" @click="notifyClear">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: { Header },
  computed: {
    show() { return this.$store.state.notification.show; },
    message() { return this.$store.state.notification.message; },
  },
  methods: {
    notifyClear() { this.$store.commit('notifyClear'); },
  },
};
</script>

<style lang="scss">
  .gtoCard {
    max-width:500px !important;
    width:96% !important;
    padding:25px !important;
    margin:10px !important;
  }
</style>
