import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    token: '',
    loggedIn: false,
    supplierName: '',
    supplierLogoUrl: '',
    supplierEmail: '',
    supplierAddress: '',
    streetOne: '',
    streetTwo: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    // billingAddress: '',
    billingStreetOne: '',
    billingStreetTwo: '',
    billingCity: '',
    billingState: '',
    billingPostalCode: '',
    billingCountry: '',
    supplierPhone: '',
    supplierFax: '',
    supplierWebsite: '',
    supplierId: '',
    supplierContacts: [],
    supplierProducts: [],
    supplierDestination: [],
    notification: {
      show: false,
      message: '',
    },
  },

  mutations: {
    notify(state, message) {
      Object.assign(state.notification, { show: true, message });
      setTimeout(() => { Object.assign(state.notification, { show: false, message: '' }); }, 3000);
    },
    notifyClear(state) {
      Object.assign(state.notification, { show: false, message: '' });
    },
    login(state, response) {
      Object.assign(state, {
        token: response.token,
        loggedIn: true,
        supplierName: response.userInfo.companyName,
        supplierEmail: response.userInfo.email,
        supplierAddress: response.userInfo.venueAddress,
        streetOne: response.userInfo.streetOne,
        streetTwo: response.userInfo.streetTwo,
        city: response.userInfo.city,
        state: response.userInfo.state,
        postalCode: response.userInfo.postalCode,
        country: response.userInfo.country,
        // billingAddress: response.userInfo.billingAddress,
        billingStreetOne: response.userInfo.billingStreetOne,
        billingStreetTwo: response.userInfo.billingStreetTwo,
        billingCity: response.userInfo.billingCity,
        billingState: response.userInfo.billingState,
        billingPostalCode: response.userInfo.billingPostalCode,
        billingCountry: response.userInfo.billingCountry,
        supplierPhone: response.userInfo.companyPhone,
        supplierFax: response.userInfo.companyFax,
        supplierWebsite: response.userInfo.website,
        supplierId: response.userInfo.id,
        supplierLogoUrl: response.userInfo.logoUrl,
      });
      console.log(this.state);
      router.push({ path: '/account' });
    },
    logOut(state) {
      Object.assign(state, {
        token: '',
        loggedIn: false,
        supplierName: '',
        supplierLogoUrl: '',
        supplierEmail: '',
        supplierAddress: '',
        streetOne: '',
        streetTwo: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        // billingAddress: '',
        billingStreetOne: '',
        billingStreetTwo: '',
        billingCity: '',
        billingState: '',
        billingPostalCode: '',
        billingCountry: '',
        supplierPhone: '',
        supplierFax: '',
        supplierWebsite: '',
        supplierId: '',
        supplierContacts: [],
        supplierProducts: [],
        supplierDestination: [],
      });
    },
    updateContacts(state, contacts) {
      Object.assign(state, {
        supplierContacts: contacts,
      });
    },
    updateProducts(state, products) {
      Object.assign(state, {
        supplierProducts: products,
      });
    },
    updateAccount(state, newInfo) {
      Object.assign(state, {
        supplierEmail: newInfo.email,
        supplierAddress: newInfo.address,
        streetOne: newInfo.streetOne,
        streetTwo: newInfo.streetTwo,
        city: newInfo.city,
        state: newInfo.state,
        postalCode: newInfo.postalCode,
        country: newInfo.country,
        // billingAddress: newInfo.billingAddress,
        billingStreetOne: newInfo.billingStreetOne,
        billingStreetTwo: newInfo.billingStreetTwo,
        billingCity: newInfo.billingCity,
        billingState: newInfo.billingState,
        billingPostalCode: newInfo.postalCode,
        billingCountry: newInfo.billingCountry,
        supplierPhone: newInfo.phone,
        supplierFax: newInfo.fax,
        supplierWebsite: newInfo.website,
      });
    },
    updateAccountLogo(state, newUrl) {
      Object.assign(state, {
        supplierLogoUrl: newUrl,
      });
    },
  },

  actions: {
    login(context, loginData) {
      fetch(`${process.env.VUE_APP_API_URL}/api/login-supplier`,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(loginData) })
        .then(async (response) => {
          console.log(response);
          if (response.status !== 200) return context.commit('notify', 'Login Failed.');
          const newRes = await response.json();
          return context.commit('login', newRes);
        })
        .catch((err) => console.log(err));
    },
    async getContacts(context) {
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/get-contacts`;
        const response = await fetch(url, { method: 'GET', headers: { Authorization: `Bearer ${this.state.token}` } });
        const data = await response.json();
        if (response.status !== 200) return context.commit('notify', 'Failed to load contacts.');
        return context.commit('updateContacts', data);
      } catch (err) {
        console.log(err);
        return context.commit('notify', 'Failed to load contacts.');
      }
    },
    getProducts(context) {
      fetch(`${process.env.VUE_APP_API_URL}/api/get-products`,
        { method: 'GET', headers: { Authorization: `Bearer ${this.state.token}` } })
        .then(async (response) => {
          const data = await response.json();
          if (response.status === 200) {
            context.commit('updateProducts', data);
          } else {
            context.commit('notify', 'Failed to load products.');
          }
        })
        .catch((err) => console.log(err));
    },
  },

  modules: {
  },
  plugins: [createPersistedState()],
});
