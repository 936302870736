<template>
<v-card
  elevation="2"
  class="gtoCard"
>
  <v-form v-on:keyup.enter="login">
    <v-container>
      <v-row>
        <v-col>

          <v-text-field
            v-model="loginData.username"
            label="Username"
            required
          ></v-text-field>

          <br>

          <v-text-field
            v-model="loginData.password"
            label="Password"
            type="password"
            required
          ></v-text-field>

          <br>

          <v-card-actions>
            <v-btn color="#283c86" text @click="forgotPwCard">Forgot Password</v-btn>
            <v-btn color="#283c86" text @click="change">Register</v-btn>
            <v-spacer></v-spacer>
            <v-btn style="background: #40832F; color: #FFF;" @click="login">Log In</v-btn>
          </v-card-actions>

        </v-col>
      </v-row>
    </v-container>
  </v-form>
</v-card>
</template>

<script>
export default {
  name: 'LoginCard',
  data() {
    return {
      loginData: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    forgotPwCard() {
      this.$emit('to-forgot-pw');
    },
    change() {
      this.$emit('child-data');
    },
    login() {
      this.$store.dispatch('login', this.loginData);
    },
  },
};
</script>
